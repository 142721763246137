
import { computed, defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: String as PropType<string | number | null>,
      default: "",
    },
  },
  setup(props, { emit }) {
    const inputValue = computed({
      get: () => props.value,
      set: (value) => emit("update:value", value),
    });

    return {
      inputValue,
    };
  },
});
