import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center" }
const _hoisted_2 = { class: "w-full text-sm mb-1 font-semibold" }
const _hoisted_3 = ["type", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.label) + " : ", 1),
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
      type: _ctx.type,
      placeholder: _ctx.placeholder,
      class: "input w-full box border border-gray-400 dark:bg-dark-4"
    }, null, 8, _hoisted_3), [
      [_vModelDynamic, _ctx.inputValue]
    ])
  ]))
}